<script>
  import { onMount, onDestroy, createEventDispatcher } from "svelte";
  import Choices from "choices.js";
  import { getWrittenName } from "../../utils/utilities";

  export let options = []; // List of options
  export let selected = ""; // Selected option
  export let placeholder = "Select an option"; // Placeholder text by default
  export let id = ""; // ID for the select element
  export let showLabel = false; // Show label or not
  export let label = ""; // Label text
  export let required = false; // Required field or not
  export let item = null; // Item to get the dataObject from
  export let currency = ""; // Currency
  export let isError = false; // Error state
  export let isForChat = false; // Button to submit the form

  let selectElement; // Reference to the select element
  let choicesInstance; // Choices.js instance

  $: value = "";

  const dispatch = createEventDispatcher();

  // Intialize Choices.js
  onMount(() => {
    choicesInstance = new Choices(selectElement, {
      placeholderValue: placeholder,
      searchPlaceholderValue: placeholder,
      position: isForChat ? "auto" : "bottom",
      searchEnabled: true,
      searchChoices: true,
      removeItemButton: false,
      shouldSort: false,
      renderSelectedChoices: false,
      itemSelectText: "",
      fuseOptions: {
        includeScore: true,
        threshold: 0.1,
      },
    });

    // Syncronize the selected value
    choicesInstance.setChoiceByValue(selected);

    // Listen to changes
    selectElement.addEventListener("change", handleSelectionChange);
  });

  // Destroy Choices.js instance when component is destroyed
  onDestroy(() => {
    if (choicesInstance) {
      choicesInstance.destroy();
    }
  });

  // Emmits the change event
  const handleSelectionChange = (event) => {
    if (isForChat) {
      value = event.target.value;
    } else {
      selected = event.target.value;
      dispatch("change", selected);
    }
  };

  // Emmits the action event
  const action = () => {
    dispatch("message", value);
    value = "";
  };
</script>

{#if showLabel}
  <label for={id}>{label}{required ? "*" : ""}</label>
{/if}
<div class={isForChat ? "scale-in-center" : ""}>
  <select bind:this={selectElement} {id} {required} class={isError ? "error-border" : ""}>
    <option value="">{placeholder}</option>
    {#each options as option}
      <option value={option} selected={option === selected}>{getWrittenName(option, item?.dataObject, item?.showPrices, currency)}</option>
    {/each}
  </select>
  {#if isForChat}
    <button on:click={() => action()} class="chatwith-chat-checkbutton">
      <svg style="width: 18px;" aria-hidden="true" focusable="false" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"
        ><path
          fill="currentColor"
          d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"
        /></svg
      >
    </button>
  {/if}
</div>
