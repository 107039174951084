<script>
  export let data;
  export let height;
  export let widgetType = "Classic";
  export let up;
  export let videoSplashOpen;

  import { createEventDispatcher } from "svelte";

  const dispatch = createEventDispatcher();

  let styles;
  let scale = 1;
  let fontscale = 1;

  $: {
    if (data.expandFullScreenMobile) styles = "top:" + (height - 28 - 28 * scale) + "px;";

    if (data.iconSize) {
      scale = data.iconSize.slice(1);
      scale = scale > 1 ? scale / 10 + 1 : scale;
      if (scale > 1) {
        fontscale = scale * 0.8; //reduce text size a little
      }
    }
  }

  function handleClick() {
    dispatch("close");
  }
</script>

<div class="chatwith-inline-wrapper" style="--textColor:{data.textColor};{videoSplashOpen && data.expandFullScreenMobile && 'display: none;'}">
  {#if (up || videoSplashOpen) && widgetType !== "BigPerson"}
    {#if !data.widgetPopUp}
      <div on:click={handleClick} class="chatwith-mainbutton without-text" style={styles}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="{scale * 45}px" height="{scale * 45}px">
          <path
            fill={data.color}
            d="M504.1,256C504.1,119,393,7.9,256,7.9C119,7.9,7.9,119,7.9,256C7.9,393,119,504.1,256,504.1C393,504.1,504.1,393,504.1,256z"
          />
          <path
            fill="var(--fgColor)"
            d="M285,256l72.5-84.2c7.9-9.2,6.9-23-2.3-31c-9.2-7.9-23-6.9-30.9,2.3L256,222.4l-68.2-79.2c-7.9-9.2-21.8-10.2-31-2.3c-9.2,7.9-10.2,21.8-2.3,31L227,256l-72.5,84.2c-7.9,9.2-6.9,23,2.3,31c4.1,3.6,9.2,5.3,14.3,5.3c6.2,0,12.3-2.6,16.6-7.6l68.2-79.2l68.2,79.2c4.3,5,10.5,7.6,16.6,7.6c5.1,0,10.2-1.7,14.3-5.3c9.2-7.9,10.2-21.8,2.3-31L285,256z"
          />
        </svg>
      </div>
    {/if}
  {:else if data.buttonMessage && data.buttonMessage != "null" && data.buttonMessage != null}
    <div on:click={handleClick} class="chatwith-mainbutton" style={styles}>
      <span class="chatwith-text" style="font-size:calc({fontscale}*16px);">
        {data.buttonMessage}
      </span>
      <img
        class="chatwith-img"
        style="width:calc({scale}*45px);height:calc({scale}*45px);"
        alt="tochat-whatsapp"
        src={data.whatsappIconUrl ?? "process.env.CDN_HOST" + "/icns/gallery/whatsapps/whatsapp-icon.png"}
      />
      {#if data.showCounter === true}
        <span class="no1 {data.rightpos == 0 ? 'left' : 'right'}">1</span>
      {/if}
    </div>
  {:else}
    <div on:click={handleClick} class="chatwith-mainbutton without-text" style={styles}>
      <img
        class="chatwith-img"
        style="width:calc({scale}*45px);height:calc({scale}*45px);padding: calc({scale}*5px);"
        alt="tochat-whatsapp"
        src={data.whatsappIconUrl ?? "process.env.CDN_HOST" + "/icns/gallery/whatsapps/whatsapp-icon.png"}
      />
      {#if data.showCounter === true}
        <span class="no1 {data.rightpos == 0 ? 'left' : 'right'}">1</span>
      {/if}
    </div>
  {/if}
</div>
