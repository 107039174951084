<script>
  import Calendar from "./Calendar.svelte";
  import Scheduler from "./Scheduler.svelte";
  import BookingForm from "./BookingForm.svelte";
  import BookingInfo from "./BookingInfo.svelte";
  import Confirmed from "./Confirmed.svelte";
  import Closer from "../base/Closer.svelte";
  import { createEventDispatcher } from "svelte";
  import { fade } from "svelte/transition";
  import { onMount } from "svelte";
  import { getBookingStatus } from "../../utils/sendwhatsapp_rewrite_chabi";

  const dispatch = createEventDispatcher();

  export let bookingConfig;
  export let data;
  export let person;
  export let noclose = false;

  let showBookingInfo = true;
  let showCalendar = true;
  let showScheduler = false;
  let showBookingForm = false;
  let showConfirmed = false;
  let showFailed = false;
  let dateID = "";
  let dateHeading = "";
  let isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  let dayStore = [];
  let bookingTime;
  let bookingObj = {};

  onMount(() => {
    if (data.orderBookingForm || bookingConfig.orderBookingForm) {
      showCalendar = false;
      showBookingForm = true;
      showBookingInfo = false;
    }
  });

  const handleDatePick = (value) => {
    showBookingInfo = false;
    showCalendar = false;
    showScheduler = true;
    dateID = value.detail.dateID;
    dayStore = value.detail.dayStore;
    makeDateHeading();
  };

  const handleTimePick = async (event) => {
    bookingTime = dayStore[event.detail];
    showScheduler = false;
    showCalendar = false;
    if (data.orderBookingForm || bookingConfig.orderBookingForm) {
      const res = await getBookingStatus(bookingObj?.booking?.confId, bookingTime?.time?.substr(0, 25), bookingTime?.time?.substr(26));
      if (res.data?.status) {
        showConfirmed = true;
        dispatch("submit", {
          data: bookingObj.data,
          url: bookingObj?.url,
          booking: {
            confId: bookingObj?.booking?.confId,
            startTime: bookingTime?.time?.substr(0, 25),
            endTime: bookingTime?.time?.substr(26),
            timezone: bookingObj?.booking?.timezone,
          },
        });
      } else {
        showFailed = true;
      }
    } else {
      showBookingForm = true;
    }
  };

  const handleSubmit = async (event) => {
    bookingObj = {
      data: event.detail.data,
      url: event.detail?.url,
      booking: event.detail.booking,
    };
    if (data.orderBookingForm || bookingConfig.orderBookingForm) {
      showCalendar = true;
    } else {
      const res = await getBookingStatus(bookingObj?.booking?.confId, bookingObj?.booking?.startTime, bookingObj?.booking?.endTime);
      if (res.data?.status) {
        dispatch("submit", bookingObj);
        if (event.detail?.isClosedConversation) {
          return;
        }
        showConfirmed = true;
      } else {
        showFailed = true;
      }
    }
    showBookingInfo = false;
    showBookingForm = false;
  };

  const handleBack = () => {
    if (showBookingForm) {
      if (data.orderBookingForm || bookingConfig.orderBookingForm) {
        dispatch("close");
      } else {
        showBookingForm = false;
        showScheduler = true;
        showCalendar = false;
      }
    } else if (showScheduler) {
      showBookingInfo = true;
      showScheduler = false;
      showCalendar = true;
    }
  };

  const makeDateHeading = () => {
    let dateAsHeading = dateID.replace(/_/g, " ");
    let date = new Date(`${dateAsHeading}`);
    return (dateHeading = date.toLocaleString(navigator.language ?? "en-US", {
      weekday: "long",
      day: "numeric",
      month: "long",
      year: "numeric",
    }));
  };

  const clickOutside = (node) => {
    const handleClick = (event) => {
      if (node && !node.contains(event.target) && !event.defaultPrevented) {
        node.dispatchEvent(new CustomEvent("click_outside", node));
      }
    };
    document.addEventListener("click", handleClick, true);
    return {
      destroy() {
        document.removeEventListener("click", handleClick, true);
      },
    };
  };
</script>

<svelte:head>
  <link rel="stylesheet" href="https://unpkg.com/mono-icons@1.0.5/iconfont/icons.css" />
</svelte:head>
<div
  class:isMobile
  class="chatwith-booking"
  style="width: {showScheduler ? 1080 : 800}px"
  use:clickOutside
  on:click_outside={() => {}}
  transition:fade={{ duration: 125 }}
>
  {#if !noclose && (showConfirmed || showFailed || showBookingInfo)}
    <Closer on:close={() => dispatch("close")} />
  {/if}
  {#if showBookingInfo}
    <BookingInfo {showBookingForm} on:back={handleBack} />
  {/if}
  <div class={showConfirmed || showFailed ? "chatwith-booking-confirm-wrapper" : "chatwith-booking-content"}>
    {#if showCalendar}
      <Calendar on:click={handleDatePick} {bookingConfig} {data} />
    {:else if showBookingForm}
      <BookingForm on:submit={handleSubmit} on:back={handleBack} {data} {person} {bookingTime} {bookingConfig} {isMobile} />
    {/if}
    {#if showConfirmed || showFailed}
      <Confirmed {bookingTime} {dateHeading} {person} {data} failed={showFailed} />
    {/if}
  </div>
  {#if showScheduler}
    <Scheduler {dateHeading} on:back={handleBack} on:confirm={handleTimePick} {data} {dayStore} {bookingConfig} />
  {/if}
</div>
