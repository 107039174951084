<script>
  import SingleFaq from "./SingleFaq.svelte";
  import { createEventDispatcher } from "svelte";
  import Closer from "./base/Closer.svelte";
  import Button from "./base/Button.svelte";
  import { getproperty } from "../utils/utilities";
  import { onMount } from "svelte";

  const dispatch = createEventDispatcher();

  export let faqs;
  export let activePerson;
  export let data;

  let faqId = null;
  let hidden = true;
  let showAll = false;
  let box;

  onMount(() => {
    box.scrollIntoView();
  });

  $: fullScreenMobile = data.expandFullScreenMobile;
</script>

<div class="chatwith-faqs-wrapper" class:fullScreenMobile bind:this={box}>
  <div class="chatwith-faqs">
    <div class="faqs-title">{faqs.title ?? "FAQs"}</div>
    <div class="faqs-shortcuts">
      {#each faqs.faqs as faq, i}
        {#if faq.shortcutName !== null}
          <span
            on:click={() => {
              showAll = false;
              if (faqId === faq.id) {
                faqId = null;
                hidden = true;
              } else {
                faqId = faq.id;
                hidden = false;
              }
            }}
            class="faq-shortcut"
            style="background-color: {getproperty(faq.shortcutName, 'color')}"
          >
            <img class="sml-ico" alt={faq.shortcutName} src={getproperty(faq.shortcutName, "icon")} />
            {#if faq.translateShortCut}{faq.translateShortCut}{:else}{faq.shortcutName}{/if}
          </span>
        {/if}
      {/each}
    </div>
    <div class="faqs-content">
      {#each faqs.faqs as faq, i}
        {#if faq.shortcutName}
          <SingleFaq activeFaq={(faqId === faq.id && hidden === false) || showAll} {faq} allowToggle={false} {fullScreenMobile} />
        {:else}
          <SingleFaq activeFaq={true} {faq} allowToggle={true} {fullScreenMobile} />
        {/if}
      {/each}
    </div>
    <div class="chatwith-faqs-buttons">
      {#if activePerson.online === true}
        <Button on:click={() => dispatch("problem-not-solved")} buttonText={"WhatsApp"} showIcon={true} />
      {:else if data.widgetType !== "bigperson"}
        <Button on:click={() => dispatch("leave-message")} buttonText={data.leaveMessage ?? "Leave a message"} showIcon={false} />
      {/if}
    </div>
  </div>
</div>
