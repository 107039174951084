<script>
  import { createEventDispatcher, onMount, tick } from "svelte";
  import FormValidate from "../FormValidate.svelte";
  import TelField from "../base/TelField.svelte";
  import { toArray, getWrittenName, isIPhone, splitNameAndPrice } from "../../utils/utilities";
  import BackButton from "./BackButton.svelte";
  import { fileUpload } from "../../utils/sendwhatsapp_rewrite_chabi";
  import Spinner from "../base/Spinner.svelte";
  import Button from "../base/Button.svelte";
  import { currentLanguage, isClosedConversationStore } from "../../store";
  import Toast from "../base/Toast.svelte";
  import translations from "../../translations";

  const dispatch = createEventDispatcher();

  export let data;
  export let person;
  export let bookingTime;
  export let bookingConfig;

  let errorList = [];
  let formValidateList = [];
  let isLoading = false;
  let currentLang = "en";
  $: isSent = false;
  $: sendMessage = data.transSuccessMessage ?? "Your message has been sent";
  $: totalPrice = calculateTotalPrice();

  let formitems = {
    info: data.translateEnterTheDetails ?? "Enter the details",
    items: person.form.items,
    buyLinks: person.form.buyLinks,
    buttonType: "whatsapp",
    buttontext: data.translateScheduleEvent ?? "Schedule event",
    showTotal: person.form.showTotal,
  };
  console.log("formitems", formitems);
  let filtered = formitems.items.map((item) => item.fieldToFilterFrom);
  formitems.items.forEach((a) => (a.val = ""));

  onMount(() => {
    // Subscribe to changes in currentLanguage
    currentLanguage.subscribe((value) => {
      currentLang = value;
    });

    formitems.items.forEach((item, i) => {
      if (item.type === "selectAndPrice" && item.canMultiply) {
        item.amount = 1;
      }
    });
  });

  const handleSubmit = async (url = null) => {
    if (isLoading) {
      return;
    }
    for (let i in formValidateList)
      try {
        formValidateList[i].validate();
      } catch (ex) {
        //console.log(ex);
      }
    if (errorList.length == 0) {
      for (let i in formitems.items) {
        if (formitems.items[i].type === "file" && formitems.items[i].val && !filtered[i]) {
          isLoading = true;
          const res = await fileUpload(formitems.items[i].val, person.id);
          formitems.items[i].val = res?.data?.id;
        }
        if (formitems.items[i].type === "hidden") {
          formitems.items[i].val = formitems.items[i].value ?? formitems.items[i].hval;
        }
      }
      isLoading = false;

      dispatch("submit", {
        data: formitems.items.filter((item, i) => item.type !== "freetext" && !filtered[i]),
        url: url,
        booking: {
          confId: bookingConfig?.id,
          startTime: bookingTime?.time?.substr(0, 25),
          endTime: bookingTime?.time?.substr(26),
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        },
      });
    }
  };

  const handleKeyPress = () => {
    if (event.code == "Enter") {
      event.preventDefault();
    }
  };

  const handleBlur = (event, index) => {
    if (event.code == "Enter") {
      event.preventDefault();
    }
    formitems.items.forEach((item, i) => {
      checkFilter(i);
    });

    if (formitems.showTotal) {
      totalPrice = calculateTotalPrice();
    }

    if (formitems.items[index].type === "select") {
      const option = formitems.items[index].dataObject.find((obj) => obj.value === formitems.items[index].val);
      if (option.closeConversation) {
        isClosedConversationStore.set(true);
        isSent = true;
        sendMessage = option.closeConversationMessage;
        dispatch("submit", {
          data: formitems.items.filter((item, i) => item.type !== "freetext" && !filtered[i]),
          booking: {
            confId: bookingConfig?.id,
            startTime: bookingTime?.time?.substr(0, 25),
            endTime: bookingTime?.time?.substr(26),
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          },
          isClosedConversation: true,
        });
      }
    }

    // if field type is selectAndPrice, check min and max amount
    if (formitems.items[index].type === "selectAndPrice" && formitems.items[index].canMultiply) {
      checkMinAndMaxAmount(formitems.items[index]);
    }
  };

  const handleFileSelected = (e, i) => {
    formitems.items[i].val = e.target.files[0];
  };

  let isDateInput = Array(person.form.items.length).fill(false);
  let isTimeInput = Array(person.form.items.length).fill(false);

  function onDateFocus(index) {
    isDateInput[index] = true;
  }

  function onDateBlur(index) {
    isDateInput[index] = false;
  }

  function onTimeFocus(index) {
    isTimeInput[index] = true;
  }

  function onTimeBlur(index) {
    isTimeInput[index] = false;
  }

  const checkFilter = (index) => {
    if (index < 0) {
      return false;
    }
    const item = formitems.items[index];
    if (!item.fieldToFilterFrom) {
      return false;
    }
    const values = item.valueToFilterFrom.split("|");

    const checkItem = formitems.items[item.fieldToFilterFrom - 1];
    let isMatch = true;
    if (filtered[item.fieldToFilterFrom - 1] !== true) {
      values.forEach((value) => {
        if (checkItem.type === "checkbox") {
          if (checkItem.val === (value === 1 || value === "1")) {
            isMatch = false;
          }
        } else if (value.trim() === checkItem.val.trim()) {
          isMatch = false;
        }
      });
    }

    filtered[index] = isMatch;
    return isMatch;
  };

  function checkMinAndMaxAmount(item) {
    let selectedOption = item.dataObject.find((option) => option.value === item.val);
    if (selectedOption) {
      item.minAmount = selectedOption.canMultiplyMin;
      item.maxAmount = selectedOption.canMultiplyMax;
    }
  }

  async function selectOption(data, item, element) {
    await tick();

    if (item.type === "selectAndPrice" && item.canMultiply) {
      checkMinAndMaxAmount(item);
    }

    // check if has to close the conversation
    const option = item.dataObject.find((obj) => obj.value === item.val);
    if (option.closeConversation) {
      sendMessage = option.closeConversationMessage;
    }

    formitems.items.forEach((_, i) => {
      checkFilter(i);
    });

    if (formitems.showTotal) {
      totalPrice = calculateTotalPrice();
    }
  }

  async function unselectOption(data, item, element) {
    await tick();

    if (item.type === "selectAndPrice" && item.canMultiply) {
      item.amount = 1;
      item.minAmount = undefined;
      item.maxAmount = undefined;
    }

    formitems.items.forEach((_, i) => {
      checkFilter(i);
    });
  }

  function calculateTotalPrice() {
    let total = 0;
    let filteredItems = formitems.items.filter((item, i) => !filtered[i]);
    for (const item of filteredItems) {
      // For "selectAndPrice"
      if (item.type === "selectAndPrice") {
        if (item.val) {
          const option = item.dataObject && item.dataObject.find((opt) => opt.value === item.val);
          if (option) {
            const numericPrice = parseFloat(option.price) || 0;
            // If canmultiply, use item.amount; otherwise, use the price as is
            const amount = item.canMultiply ? parseFloat(item.amount) || 1 : 1;
            total += numericPrice * amount;
          }
        }
      }
      // For "selectBooking"
      else if (item.type === "selectBooking") {
        if (item.selectVal) {
          const option = item.dataObject && item.dataObject.find((opt) => opt.value === item.selectVal);
          if (option) {
            const price = option.price;
            total += parseFloat(price) || 0;
          }
        }
      }
      // For "selectBookingFullDay"
      else if (item.type === "selectBookingFullDay") {
        if (item.selectVal && item.startDateVal && item.endDateVal) {
          const option = item.dataObject && item.dataObject.find((opt) => opt.value === item.selectVal);
          if (option) {
            const numericPrice = parseFloat(option.price) || 0;
            // Calculate the number of days between the start and end dates
            const start = new Date(item.startDateVal);
            const end = new Date(item.endDateVal);
            const diffTime = Math.abs(end - start);
            const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
            total += numericPrice * diffDays;
          }
        }
      }
      // For "selectBookingAdvanced"
      else if (item.type === "selectBookingAdvanced") {
        if (item.selectVal) {
          const option = item.dataObject && item.dataObject.find((opt) => opt.value === item.selectVal);
          if (option) {
            const numericPrice = parseFloat(option.price) || 0;
            // If canmultiply, use item.amount; otherwise, use the price as is
            const amount = item.canMultiply ? parseFloat(item.amount) || 1 : 1;
            total += numericPrice * amount;
          }
        }
      }
      // For "checkbox"
      else if (item.type === "checkbox") {
        if (item.val && item.price) {
          total += parseFloat(item.price) || 0;
        }
      }
      // For "freetext"
      else if (item.type === "freetext") {
        if (item.showPrice && item.price) {
          total += parseFloat(item.price) || 0;
        }
      }
    }
    return total;
  }
</script>

<div class="chatwith-booking-form">
  <div class="chatwith-booking-back" on:click={() => dispatch("back")}>
    <BackButton />
  </div>
  {#if formitems.info != ""}
    <div class="chatwith-booking-form-title">{formitems.info}</div>
  {/if}
  {#each formitems.items as item, i}
    <div class:hidden={filtered[i]}>
      {#if item.type == "text"}
        <div class="chatwith-booking-form-label">{item.label}</div>
        <div class="chatwith-booking-form-input">
          <input
            bind:value={item.val}
            type="text"
            class={item.isError ? "error-border" : ""}
            placeholder={`${item.label}${item.required ? " *" : ""}`}
            on:keypress={handleKeyPress}
            on:keyup={(evt) => handleBlur(evt, i)}
          />
        </div>
      {:else if item.type == "file"}
        <div class="chatwith-booking-form-label">Please upload {item.label}</div>
        <div class="chatwith-booking-form-input">
          <input
            bind:value={item.val}
            type="file"
            class={item.isError ? "error-border" : ""}
            accept=".pdf, .docx, .xlsx, .jpg, .jpeg, .png, .zip"
            on:change={(e) => handleFileSelected(e, i)}
          />
        </div>
      {:else if item.type == "number"}
        <div class="chatwith-booking-form-label">{item.label}</div>
        <div class="chatwith-booking-form-input">
          <input
            bind:value={item.val}
            type="number"
            class={item.isError ? "error-border" : ""}
            placeholder={`${item.label}${item.required ? " *" : ""}`}
            on:keypress={handleKeyPress}
            on:keyup={(evt) => handleBlur(evt, i)}
          />
        </div>
      {:else if item.type == "url"}
        <div class="chatwith-booking-form-label">{item.label}</div>
        <div class="chatwith-booking-form-input">
          <input
            bind:value={item.val}
            type="url"
            class={item.isError ? "error-border" : ""}
            placeholder={`${item.label}${item.required ? " *" : ""}`}
            on:keypress={handleKeyPress}
            on:keyup={(evt) => handleBlur(evt, i)}
          />
        </div>
      {:else if item.type == "date"}
        <div class="chatwith-booking-form-label">{item.label}</div>
        <div class="chatwith-booking-form-input">
          {#if isIPhone()}
            {#if isDateInput[i]}
              <input
                class="form-element-input {item.isError ? 'error-border' : ''}"
                bind:value={item.val}
                type="date"
                on:blur={() => onDateBlur(i)}
                on:keypress={handleKeyPress}
                on:change={(evt) => handleBlur(evt, i)}
              />
            {:else}
              <input
                class="form-element-input {item.isError ? 'error-border' : ''}"
                bind:value={item.val}
                type="text"
                on:focus={() => onDateFocus(i)}
                placeholder={item.label}
                on:keypress={handleKeyPress}
                on:change={(evt) => handleBlur(evt, i)}
              />
            {/if}
          {:else}
            <input
              class="form-element-input {item.isError ? 'error-border' : ''}"
              bind:value={item.val}
              type="text"
              onfocus="(this.type='date')"
              placeholder={`${item.label}${item.required ? " *" : ""}`}
              on:keypress={handleKeyPress}
              on:change={(evt) => handleBlur(evt, i)}
            />
          {/if}
        </div>
      {:else if item.type == "time"}
        <div class="chatwith-booking-form-label">{item.label}</div>
        <div class="chatwith-booking-form-input">
          {#if isIPhone()}
            {#if isTimeInput[i]}
              <input
                class="form-element-input {item.isError ? 'error-border' : ''}"
                bind:value={item.val}
                type="time"
                on:blur={() => onTimeBlur(i)}
                on:keypress={handleKeyPress}
                on:change={(evt) => handleBlur(evt, i)}
              />
            {:else}
              <input
                class="form-element-input {item.isError ? 'error-border' : ''}"
                bind:value={item.val}
                type="text"
                on:focus={() => onTimeFocus(i)}
                placeholder={item.label}
                on:keypress={handleKeyPress}
                on:change={(evt) => handleBlur(evt, i)}
              />
            {/if}
          {:else}
            <input
              class="form-element-input {item.isError ? 'error-border' : ''}"
              bind:value={item.val}
              type="text"
              onfocus="(this.type='time')"
              placeholder={`${item.label}${item.required ? " *" : ""}`}
              on:keypress={handleKeyPress}
              on:change={(evt) => handleBlur(evt, i)}
            />
          {/if}
        </div>
      {:else if item.type == "select"}
        <div class="chatwith-booking-form-label">{item.label}</div>
        {#if !item.showAsButton && !item.showAsTable}
          <div class="chatwith-booking-form-input">
            <div class="select">
              <!-- svelte-ignore a11y-no-onchange -->
              <select bind:value={item.val} class={item.isError ? "error-border" : ""} on:change={(evt) => handleBlur(evt, i)}>
                <option value="">--{item.label}--</option>
                {#each toArray(item.data) as data}
                  <option value={data}>{getWrittenName(data, item?.dataObject)}</option>
                {/each}
              </select>
            </div>
          </div>
        {:else}
          <div class="chatwith-button-options" class:buttons-as-table={item.showAsTable}>
            {#each toArray(item.data) as data}
              <button
                type="button"
                class="chatwith-option-button {item.val == data ? 'selected' : ''}"
                on:click={(e) => {
                  selectOption(data, item, e.target);
                  item.val = data;
                }}
              >
                {getWrittenName(data, item?.dataObject)}
              </button>
            {/each}
          </div>
        {/if}
      {:else if item.type == "selectAndPrice"}
        <div class="chatwith-booking-form-label">{item.label}</div>
        {#if !item.showAsButton && !item.showAsTable}
          <div class="chatwith-booking-form-input">
            <div class="select">
              <!-- svelte-ignore a11y-no-onchange -->
              <select bind:value={item.val} class={item.isError ? "error-border" : ""} on:change={(evt) => handleBlur(evt, i)}>
                <option value="">--{item.label}--</option>
                {#each toArray(item.data) as itemData}
                  <option value={itemData}>{getWrittenName(itemData, item?.dataObject, item?.showPrices, data?.currency)}</option>
                {/each}
              </select>
            </div>
          </div>
        {:else}
          <div class="chatwith-button-options" class:buttons-as-table={item.showAsTable}>
            {#each toArray(item.data) as itemData}
              <button
                type="button"
                class="chatwith-option-button {item.val == itemData ? 'selected' : ''}"
                on:click={(e) => {
                  if (item.val === itemData) {
                    item.val = "";
                    unselectOption(itemData, item, e.target);
                  } else {
                    item.val = itemData;
                    selectOption(itemData, item, e.target);
                  }
                }}
              >
                <div class="chatwith-option-button-text">
                  <span class="chatwith-option-button-name"
                    >{splitNameAndPrice(getWrittenName(itemData, item?.dataObject, item?.showPrices, data?.currency)).name}</span
                  >
                  {#if item?.showPrices}
                    <small class="chatwith-option-button-price"
                      >({splitNameAndPrice(getWrittenName(itemData, item?.dataObject, item?.showPrices, data?.currency)).price})</small
                    >
                  {/if}
                </div>
              </button>
            {/each}
          </div>
        {/if}
        {#if item.canMultiply}
          <div class="chatwith-booking-form-label">{data.translateAmounts || translations.amount[currentLang] || "Amount"}:</div>
          <div class="chatwith-booking-form-input">
            <input
              id={`amount-${item.id}`}
              type="number"
              min={item.minAmount ?? 0}
              max={item.maxAmount}
              bind:value={item.amount}
              placeholder={data.translateAmounts || translations.enterAmount[currentLang] || "Enter amount"}
              on:change={(evt) => handleBlur(evt, i)}
            />
          </div>
        {/if}
      {:else if item.type == "checkbox"}
        <div class="chatwith-booking-form-label">{""}</div>
        <div class="chatwith-booking-form-input">
          <div>
            <label>
              <input value={false} bind:checked={item.val} type="checkbox" on:change={(evt) => handleBlur(evt, i)} />
              <p style="font-size: 12px">{item.label}</p>
            </label>
          </div>
        </div>
      {:else if item.type == "hidden"}
        <div class="chatwith-booking-form-label" style="display: none">{item.label}</div>
        <div class="chatwith-booking-form-input">
          <input bind:value={item.value} type="hidden" />
        </div>
      {:else if item.type == "tel"}
        <div class="chatwith-booking-form-label">{item.label}</div>
        <div class="chatwith-booking-form-input">
          <TelField
            {data}
            bind:value={item.val}
            bind:telNum={item.telNum}
            placeholder={`${item.label}${item.required ? " *" : ""}`}
            isError={item.isError}
            on:message={(evt) => handleBlur(evt, i)}
          />
        </div>
      {:else if item.type == "zipcode"}
        <div class="chatwith-booking-form-label">{item.label}</div>
        <div class="chatwith-booking-form-input">
          <input
            id={item.id}
            bind:value={item.val}
            type="text"
            isError={item.isError}
            placeholder={`${item.label}${item.required ? "*" : ""}`}
            on:keypress={handleKeyPress}
            on:keyup={(evt) => handleBlur(evt, i)}
          />
        </div>
      {:else if item.type == "email"}
        <div class="chatwith-booking-form-label">{item.label}</div>
        <div class="chatwith-booking-form-input">
          <input
            bind:value={item.val}
            type="email"
            class={item.isError ? "error-border" : ""}
            placeholder={`${item.label}${item.required ? " *" : ""}`}
            on:keypress={handleKeyPress}
            on:keyup={(evt) => handleBlur(evt, i)}
          />
        </div>
      {:else if item.type == "freetext" && item.hval}
        <div style="font-size: 13px !important;" class="chatwith-booking-form-label">
          {@html item.hval}
        </div>
      {/if}
      {#if !filtered[i]}
        <FormValidate
          bind:value={errorList}
          bind:this={formValidateList[i]}
          on:error={(val) => {
            item.isError = val.detail;
          }}
          {item}
          {data}
          index={i}
        />
      {/if}
      {#if item.type == "checkbox" && item.legalUrl}
        <div style="font-size: 12px" class="chatwith-booking-form-label">{""}</div>
        <div class="chatwith-booking-form-input">
          <a style="font-size: 12px" target="_blank" href={item.legalUrl}>{item.legalUrlText ?? "Click here to see the Privacy Policy"}</a>
        </div>
      {/if}
    </div>
  {/each}
  {#if isLoading}
    <Spinner />
  {/if}
  {#if isSent}
    <Toast message={sendMessage} visible={isSent} />
  {/if}
  {#if formitems.buyLinks && formitems.buyLinks.length > 0}
    {#each formitems.buyLinks as item, i}
      <Button
        id={"chatwith-buy-button" + i}
        on:click={() => handleSubmit(item.link)}
        buttonText={item.buttontext}
        showIcon={true}
        iconUrl={data.whatsappIconUrl}
        spanClassName={item.buttontext.length >= 25 ? "chatwith-button-long-span" : ""}
      />
    {/each}
  {:else}
    <div class="chatwith-booking-form-btn-container">
      {#if formitems.showTotal || formitems.showTotalDuration}
        <div class="cw-total-info">
          {#if formitems.showTotal}
            <div class="cw-total-price">
              <span>{translations.totalPrice[currentLang]}: <b>{totalPrice.toFixed(2)} {data.currency}</b></span>
            </div>
          {/if}
        </div>
      {/if}
      <button class="chatwith-booking-form-btn" on:click={() => handleSubmit(null)}>
        {formitems.buttontext}
      </button>
    </div>
  {/if}
</div>
