<script>
  import { dayjs } from "svelte-time";
  import translations from "../../translations";
  export let bookingTime;
  export let dateHeading;
  export let person;
  export let data;
  export let failed;

  let startTime = dayjs(bookingTime?.time?.substr(0, 25))?.format("HH:mm");
  let endTime = dayjs(bookingTime?.time?.substr(26))?.format("HH:mm");
  let currentLang = navigator.language.split("-")[0] || "en";
</script>

<div class="chatwith-booking-form">
  {#if failed}
    <div class="chatwith-booking-form-title">{translations.failedBooking[currentLang] ?? "Failed Schedule"}</div>
  {:else}
    {#if person.bookingConfigs[0].confirmBooking}
      <div class="chatwith-booking-form-title">{translations.pendingBooking[currentLang] ?? "Pending Schedule"}</div>
    {:else}
      <div class="chatwith-booking-form-title">{translations.confirmedBooking[currentLang] ?? "Confirmed Schedule"}</div>
    {/if}
    <div class="chatwith-booking-form-desc">
      {translations.youHaveScheduledAnEventWith[currentLang] ?? "You have scheduled in event with"}{" "}{person.name}
    </div>
  {/if}
  <div class="chatwith-booking-event-desc">
    <i class="mi mi-calendar" />
    <div>
      {startTime + ", " + dateHeading}
    </div>
  </div>
</div>
