const translations = {
  days: {
    en: "DAYS",
    es: "DÍAS",
    fr: "JOURS",
    it: "GIORNI",
    de: "TAGE",
    pl: "DNI",
    pt: "DIAS",
    ch: "天",
    ru: "ДНИ",
    my: "HARI",
    hi: "दिन",
    tr: "GÜN",
  },
  hours: {
    en: "HOURS",
    es: "HORAS",
    fr: "HEURES",
    it: "ORE",
    de: "STUNDEN",
    pl: "GODZINY",
    pt: "HORAS",
    ch: "小时",
    ru: "ЧАСЫ",
    my: "JAM",
    hi: "घंटे",
    tr: "SAAT",
  },
  minutes: {
    en: "MINUTES",
    es: "MINUTOS",
    fr: "MINUTES",
    it: "MINUTI",
    de: "MINUTEN",
    pl: "MINUTY",
    pt: "MINUTOS",
    ch: "分钟",
    ru: "МИНУТЫ",
    my: "MINIT",
    hi: "मिनट",
    tr: "DAKİKA",
  },
  seconds: {
    en: "SECONDS",
    es: "SEGUNDOS",
    fr: "SECONDES",
    it: "SECONDI",
    de: "SEKUNDEN",
    pl: "SEKUNDY",
    pt: "SEGUNDOS",
    ch: "秒",
    ru: "СЕКУНДЫ",
    my: "SAAT",
    hi: "सेकंड",
    tr: "SANİYE",
  },
  last_places_available: {
    en: "Last places available",
    es: "Últimas plazas disponibles",
    fr: "Dernières places disponibles",
    it: "Ultimi posti disponibili",
    de: "Letzte verfügbare Plätze",
    pl: "Ostatnie dostępne miejsca",
    pt: "Últimos lugares disponíveis",
    ch: "最后剩余的位置",
    ru: "Последние доступные места",
    my: "Tempat terakhir yang tersedia",
    hi: "अंतिम उपलब्ध स्थान",
    tr: "Son kalan yerler",
  },
  male: {
    en: "Male",
    es: "Masculino",
    fr: "Mâle",
    it: "Maschio",
    de: "Männlich",
    pt: "Macho",
    ch: "男性",
    ru: "мужской",
    my: "lelaki",
    hi: "पुरुष",
    tr: "Erkek",
  },
  female: {
    en: "Female",
    es: "Femenino",
    fr: "Femelle",
    it: "Femmina",
    de: "Weiblich",
    pt: "Fêmea",
    ch: "女性",
    ru: "женский",
    my: "perempuan",
    hi: "महिला",
    tr: "Kadın",
  },
  other_gender: {
    en: "Other",
    es: "Otro",
    fr: "Autre",
    it: "Altro",
    de: "Andere",
    pt: "Outro",
    ch: "其他",
    ru: "другой",
    my: "lain",
    hi: "अन्य",
    tr: "Diğer",
  },
  noAvailabilityToday: {
    en: "No available today",
    es: "No disponible hoy",
    fr: "Pas disponible aujourd",
    it: "Non disponibile oggi",
    de: "Heute nicht verfügbar",
    pl: "Dzisiaj niedostępny",
    pt: "Não disponível hoje",
    ch: "今天没有空位",
    ru: "Сегодня нет доступных мест",
    my: "Tidak ada ketersediaan hari ini",
    hi: "आज उपलब्ध नहीं है",
    tr: "Bugün uygun değil",
  },
  availableAt: {
    en: "Available in",
    es: "Disponible en",
    fr: "Disponible en",
    it: "Disponibile in",
    de: "Verfügbar in",
    pl: "Dostępny w",
    pt: "Disponível em",
    ch: "在",
    ru: "Доступно в",
    my: "Tersedia di",
    hi: "में उपलब्ध",
    tr: "Mevcut",
  },
  sentMessage: {
    en: "Your message has been sent",
    es: "Tu mensaje ha sido enviado",
    fr: "Votre message a été envoyé",
    it: "Il tuo messaggio è stato inviato",
    de: "Ihre Nachricht wurde gesendet",
    pl: "Twoja wiadomość została wysłana",
    pt: "Sua mensagem foi enviada",
    ch: "您的消息已发送",
    ru: "Ваше сообщение было отправлено",
    my: "Mesej anda telah dihantar",
    hi: "आपका संदेश भेज दिया गया है",
    tr: "Mesajınız gönderildi",
  },
  startDate: {
    en: "Start date",
    es: "Fecha de inicio",
    fr: "Date de début",
    it: "Data di inizio",
    de: "Startdatum",
    pl: "Data rozpoczęcia",
    pt: "Data de início",
    ch: "开始日期",
    ru: "Дата начала",
    my: "Tarikh mula",
    hi: "प्रारंभ तिथि",
    tr: "Başlangıç tarihi",
  },
  endDate: {
    en: "End date",
    es: "Fecha de fin",
    fr: "Date de fin",
    it: "Data di fine",
    de: "Enddatum",
    pl: "Data zakończenia",
    pt: "Data final",
    ch: "结束日期",
    ru: "Дата окончания",
    my: "Tarikh tamat",
    hi: "अंतिम तिथि",
    tr: "Bitiş tarihi",
  },
  dateRange: {
    en: "Date range",
    es: "Rango de fechas",
    fr: "Plage de dates",
    it: "Intervallo di date",
    de: "Datumsbereich",
    pl: "Zakres dat",
    hi: "तिथि सीमा",
    ms: "Julat tarikh",
    tr: "Tarih aralığı",
  },
  confirm: {
    en: "Confirm",
    es: "Confirmar",
    fr: "Confirmer",
    it: "Confermare",
    de: "Bestätigen",
    pl: "Potwierdzać",
    hi: "पुष्टि करें",
    ms: "Mengesahkan",
    tr: "Onaylamak",
  },
  amount: {
    en: "Amount",
    es: "Cantidad",
    fr: "Montant",
    it: "Quantità",
    de: "Menge",
    pl: "Ilość",
    hi: "मात्रा",
    ms: "Jumlah",
    tr: "Miktar",
  },
  enterAmount: {
    en: "Enter amount",
    es: "Introduce cantidad",
    fr: "Entrez le montant",
    it: "Inserisci importo",
    de: "Menge eingeben",
    pl: "Wprowadź ilość",
    hi: "राशि दर्ज करें",
    ms: "Masukkan jumlah",
    tr: "Miktar girin",
  },
  selectTimeRequired: {
    en: "Time required",
    es: "Tiempo obligatorio",
    fr: "Temps requis",
    it: "Tempo richiesto",
    de: "Erforderliche Zeit",
    pl: "Wymagany czas",
    hi: "आवश्यक समय",
    ms: "Masa diperlukan",
    tr: "Gerekli zaman",
  },
  totalPrice: {
    en: "Total",
    es: "Total",
    fr: "Total",
    it: "Totale",
    de: "Gesamt",
    pl: "Całkowity",
    hi: "कुल",
    ms: "Jumlah keseluruhan",
    tr: "Toplam",
  },
  totalDuration: {
    en: "Total duration",
    es: "Duración total",
    fr: "Durée totale",
    it: "Durata totale",
    de: "Gesamtdauer",
    pl: "Całkowity czas trwania",
    hi: "कुल अवधि",
    ms: "Jumlah keseluruhan",
    tr: "Toplam süre",
  },
  confirmedBooking: {
    en: "Confirmed booking",
    es: "Reserva confirmada",
    fr: "Réservation confirmée",
    it: "Prenotazione confermata",
    de: "Bestätigte Buchung",
    pl: "Potwierdzona rezerwacja",
    pt: "Reserva confirmada",
    ch: "已确认预订",
    ru: "Подтвержденное бронирование",
    hi: "पुष्टि की गई बुकिंग",
    ms: "Tempahan disahkan",
    tr: "Onaylanmış rezervasyon",
  },
  pendingBooking: {
    en: "Pending booking",
    es: "Reserva pendiente",
    fr: "Réservation en attente",
    it: "Prenotazione in sospeso",
    de: "Ausstehende Buchung",
    pl: "Oczekująca rezerwacja",
    pt: "Reserva pendente",
    ch: "待定预订",
    ru: "Ожидающее бронирование",
    hi: "बुकिंग पेंडिंग",
    ms: "Tempahan tertunda",
    tr: "Bekleyen rezervasyon",
  },
  failedBooking: {
    en: "Failed booking",
    es: "Reserva fallida",
    fr: "Réservation échouée",
    it: "Prenotazione fallita",
    de: "Fehlgeschlagene Buchung",
    pl: "Nieudana rezerwacja",
    pt: "Reserva falhada",
    ch: "预订失败",
    ru: "Неудачное бронирование",
    hi: "बुकिंग विफल",
    ms: "Tempahan gagal",
    tr: "Başarısız rezervasyon",
  },
  youHaveScheduledAnEventWith: {
    en: "You have scheduled an event with",
    es: "Has programado un evento con",
    fr: "Vous avez programmé un événement avec",
    it: "Hai programmato un evento con",
    de: "Sie haben eine Veranstaltung mit",
    pl: "Zaplanowałeś wydarzenie z",
    pt: "Você agendou um evento com",
    ch: "您已安排了一个活动",
    ru: "Вы запланировали мероприятие с",
    my: "Anda telah menjadualkan acara dengan",
    hi: "आपने के साथ एक घटना की योजना बनाई है",
    tr: "Bir etkinlik planladınız",
  },
  noAvailableTimeSlots: {
    en: "No available time slots for this day",
    es: "No hay franjas horarias disponibles para este día",
    fr: "Aucune plage horaire disponible pour ce jour",
    it: "Nessuna fascia oraria disponibile per questo giorno",
    de: "Keine verfügbaren Zeitfenster für diesen Tag",
    pl: "Brak dostępnych przedziałów czasowych na ten dzień",
    pt: "Não há horários disponíveis para este dia",
    ch: "当天没有可用的时间段",
    ru: "Нет доступных временных слотов на этот день",
    my: "Tiada slot masa yang tersedia untuk hari ini",
    hi: "इस दिन के लिए कोई उपलब्ध समय स्लॉट नहीं है",
    tr: "Bu gün için uygun zaman dilimi yok",
  }
}

export default translations;
