<script>
  import { countries } from "../../utils/countries";
  import { createEventDispatcher } from "svelte";

  const dispatch = createEventDispatcher();

  export let data;
  export let showButton = false;
  export let value = "";
  export let telNum = "";
  export let telCode = data.dialingCode;
  export let placeholder = "";
  export let isError;

  let countriesSorted = countries;
  countriesSorted.sort((a, b) => {
    // If both are the same dial code and it's 1, then US first
    if (a.Dial === b.Dial && a.Dial === "1") {
      // US first
      if (a.Iso2 === "US") return -1;
      if (b.Iso2 === "US") return 1;
    }
    // Order by dial code
    return a.Dial.localeCompare(b.Dial);
  });

  function action(key) {
    telNum = telNum.replace(/\s/g, "");
    value = telCode + telNum;
    if (key === "Enter") {
      dispatch("submit", { code: telCode, num: telNum });
    } else {
      dispatch("message", { code: telCode, num: telNum });
    }
  }
</script>

<div class="chatwith-telfield" class:showButton>
  {#if showButton === true}
    <div style="flex: 1 1 35%;">
      <select bind:value={telCode}>
        {#each countries as c}
          <option value={c.Dial}>{c.Unicode} +{c.Dial}</option>
        {/each}
      </select>
    </div>
    <div style="flex: 2 1 45%;">
      <input
        type="tel"
        class="chatwith-telfield-input-hasButton"
        style="border: 1px solid #ccc"
        placeholder={placeholder ? placeholder : data.transYourPhone ? data.transYourPhone : "Your phone number"}
        bind:value={telNum}
        on:keyup={(e) => action(e.key)}
      />
    </div>
    <div style="flex: 3 1 20%;">
      <button on:click={() => action("Enter")} class="chatwith-chat-checkbutton">
        <svg style="width: 18px;" aria-hidden="true" focusable="false" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
          <path
            fill="currentColor"
            d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206
            0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69
            432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997
            9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207
            9.997-36.204-.001z"
          />
        </svg>
      </button>
    </div>
  {:else}
    <div style="flex: 1 1 10%">
      <select bind:value={telCode} on:blur={action}>
        {#each countries as c}
          <option value={c.Dial}>{c.Unicode} +{c.Dial}</option>
        {/each}
      </select>
    </div>
    <div style="flex:2">
      <input
        type="tel"
        style="border: 1px solid {isError ? '#ff8076' : '#ccc'}"
        placeholder={placeholder ? placeholder : data.transYourPhone ? data.transYourPhone : "Your phone number"}
        bind:value={telNum}
        on:keyup={(e) => action(e.key)}
      />
    </div>
  {/if}
</div>
