<script>
  import { createEventDispatcher, tick } from "svelte";
  import axios from "axios";
  import ChatTextBox from "./chat/ChatTextBox.svelte";
  import ChatBubbleBot from "./chat/ChatBubbleBot.svelte";
  import ChatBubbleUser from "./chat/ChatBubbleUser.svelte";
  import Button from "./base/Button.svelte";
  import Closer from "./base/Closer.svelte";
  import { getHost } from "../utils/sendwhatsapp_rewrite_chabi";
  import { v4 as uuidv4 } from "uuid";
  import { addHttpsToUrl } from "../utils/utilities";

  const uuid = uuidv4();

  export let data;
  export let activePerson;

  $: chatList = [{ id: 1, botText: data.faqsChatBotQuestionText ?? "Hello, how can I help you?", userText: "" }];
  let faqs = [];
  let isSolved = false;

  $: fullScreenMobile = data.expandFullScreenMobile;

  const dispatch = createEventDispatcher();

  async function setVal(v) {
    if (activePerson.faqGrps[0].aiCollectData) {
      setValWithCollectedData(v, activePerson.id);
    } else {
      let objDiv = document.getElementById("messageBox");
      objDiv.scrollTop = objDiv.scrollHeight;
      if (v.detail.trim() == "") return;
      let text = v.detail;
      let param = {
        search: v.detail.trim(),
        previous: chatList[chatList.length - 1],
      };

      param["whatsapp"] = activePerson.id;
      param["conversation"] = uuid;

      // show user text in chat just after user sends it
      chatList[chatList.length - 1].userText = text;

      await axios
        .post(getHost() + "/api/searchfaq-ai", param)
        .then((response) => {
          faqs = response.data;

          // update user text with the answer
          chatList[chatList.length - 1].userText = text;

          if (faqs && faqs.relevance && faqs.answer && faqs.answer.relevance !== 0) {
            chatList.push({
              id: chatList.length + 1,
              botText: faqs.answer,
              userText: "",
            });

            isSolved = true;
          } else {
            chatList.push({
              id: chatList.length + 1,
              botText: data.faqsChatBotNotSolvedText ?? "Sorry, I don't have an answer for that, what else can I help you with?",
              userText: "",
            });
            isSolved = false;
            animateWhatsAppButton();
          }

          // if (faqs.extra_question){
          //   //setTimeout(() => {
          //     chatList.push({
          //       id: chatList.length+ 1,
          //       botText: faqs.extra_question,
          //       userText: ""
          //     });
          //   //}, 1000)
          // }
          let objDiv = document.getElementById("messageBox");
          scrollToBottom();
        })
        .catch((error) => {
          alert(error);
        });
    }
  }

  async function setValWithCollectedData(v, widgetId) {
    let objDiv = document.getElementById("messageBox");
    objDiv.scrollTop = objDiv.scrollHeight;
    if (v.detail.trim() == "") return;
    let text = v.detail;
    let param = {
      question: text.trim(),
      threadId: uuid, // Utiliza el UUID generado previamente
    };

    // Muestra el texto del usuario en el chat inmediatamente
    chatList[chatList.length - 1].userText = text;
    try {
      const response = await axios.post(`${getHost()}/ai/faq-data-extractor-receiver/${widgetId}`, param, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });

      const data = response.data;

      // Verifica si hay una respuesta del bot
      if (data.chat_bot_answer_about_datatext) {
        chatList = [
          ...chatList,
          {
            id: chatList.length + 1,
            botText: data.chat_bot_answer_about_datatext,
            userText: "",
          },
        ];
      }

      // Verifica si hay una pregunta siguiente
      if (data.chat_bot_next_question) {
        setTimeout(() => {
          chatList = [
            ...chatList,
            {
              id: chatList.length + 1,
              botText: data.chat_bot_next_question,
              userText: "",
            },
          ];
          isSolved = true;
          scrollToBottom();
        }, 2000);
      } else {
        isSolved = false;
        animateWhatsAppButton();
      }
      // Asegúrate de que el chat se desplaza al fondo
      scrollToBottom();
    } catch (error) {
      alert("Hubo un error procesando tu solicitud.");
    }
  }

  const animateWhatsAppButton = () => {
    let whatsappBtn = document.getElementById("whatsapp-btn");
    whatsappBtn?.classList.add("shake-btn");
    setTimeout(() => {
      whatsappBtn?.classList.remove("shake-btn");
    }, 2000);
  };

  /**
   * Scrolls to the bottom of the chat box.
   */
  const scrollToBottom = () => {
    tick().then(() => {
      const messageBox = document.getElementsByClassName("chatwith-chat-panel")[0];
      if (messageBox) {
        messageBox.scrollTo({
          top: messageBox.scrollHeight,
          behavior: "smooth",
        });
      }
    });
  };
</script>

<div class="chatwith-chat chatwith-faqschat" class:fullScreenMobile>
  <div class="chatwith-chat-panel">
    <div class="chatwith-chat-content">
      <div class="chatwith-chat-messages" id="messageBox">
        {#each chatList as element, i (element.id)}
          <div class="scale-in-left">
            <ChatBubbleBot text={element.botText} showDot={true} />
          </div>
          {#if element.userText}
            <div class="scale-in-right">
              <ChatBubbleUser bubbleData={{ val: element.userText }} />
            </div>
          {/if}
        {/each}
      </div>
    </div>
  </div>
  {#if data.widgetType === "bigperson"}
    <div class="chatwith-chat-footer-bigperson">
      <ChatTextBox on:message={setVal} fullScreenMobile={data.expandFullScreenMobile} translateChatAnswer={data.translateChatAnswer} />
      {#if !activePerson.faqGrps[0].hideWhatsappButton}
        <div class="chatwith-faqschat-buttons">
          {#if activePerson.online === true}
            <Button on:click={() => dispatch("problem-not-solved")} buttonText={data.translateChatWithAgent ?? "WhatsApp"} showIcon={true} id="whatsapp-btn" />
          {:else}
            <Button on:click={() => dispatch("leave-message")} buttonText={data.leaveMessage ?? "Leave a message"} showIcon={false} />
          {/if}
        </div>
      {/if}
      {#if data.showBrandInWidget}
        <div class="chatwith-brand" style="font-size: 13px; font-weight:600; margin-top: 10px; color: #666; text-align: center">
          {#if data.brandNameUrl}
            <span><a style="color: blueviolet; font-size: 13px;" target="_blank" href={addHttpsToUrl(data.brandNameUrl)}>{data.brandName}</a></span>
          {:else}
            <span style="font-size: 13px;">{data.brandName}</span>
          {/if}
        </div>
      {/if}
    </div>
  {/if}
</div>

{#if data.widgetType !== "bigperson"}
  <div class="chatwith-chat " style="background: #F0F0F0;">
    <div class="chatwith-chat-footer">
      <ChatTextBox
        on:message={setVal}
        hasBorder={data.widgetType === "bigperson"}
        fullScreenMobile={data.expandFullScreenMobile}
        translateChatAnswer={data.translateChatAnswer}
      />
      {#if !activePerson.faqGrps[0].hideWhatsappButton}
        <div class="chatwith-faqschat-buttons">
          {#if activePerson.online === true}
            <Button on:click={() => dispatch("problem-not-solved")} buttonText={data.translateChatWithAgent ?? "WhatsApp"} showIcon={true} id="whatsapp-btn" />
          {:else}
            <Button on:click={() => dispatch("leave-message")} buttonText={data.leaveMessage ?? "Leave a message"} showIcon={false} />
          {/if}
        </div>
      {/if}
      {#if data.showBrandInWidget}
        <div class="chatwith-brand" style="font-size: 13px; font-weight:600; margin-top: 10px; color: #666; text-align: center">
          {#if data.brandNameUrl}
            <span><a style="color: blueviolet; font-size: 13px;" target="_blank" href={addHttpsToUrl(data.brandNameUrl)}>{data.brandName}</a></span>
          {:else}
            <span style="font-size: 13px;">{data.brandName}</span>
          {/if}
        </div>
      {/if}
    </div>
  </div>
{/if}
