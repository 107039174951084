<script>
  import NewSingleFaq from "./NewSingleFaq.svelte";
  import { createEventDispatcher } from "svelte";
  import Closer from "./base/Closer.svelte";
  import Button from "./base/Button.svelte";
  import { getproperty } from "../utils/utilities";

  const dispatch = createEventDispatcher();

  export let faqs;
  export let activePerson;
  export let data;

  let faqId = null;
  let hidden = true;
  let showAll = false;
  let hideFaqs = true;

  $: fullScreenMobile = data.expandFullScreenMobile;
  $: {
    if (faqId != null || faqs.faqs.filter((faq) => !faq.shortcutName).length > 0) {
      hideFaqs = false;
    }
  }

  function bottomBorder(i) {
    return i < faqs.faqs.length - 1;
  }
</script>

<div class="chatwith-faqs-wrapper" class:fullScreenMobile>
  <div class="chatwith-faqs chatwith-newfaqs">
    <div class="faqs-title">{faqs.title ?? "FAQs"}</div>
    <div class="faqs-shortcuts">
      {#each faqs.faqs as faq, i}
        {#if faq.shortcutName !== null}
          <span
            on:click={() => {
              showAll = false;
              faqId = faq.id;
              hidden = false;
            }}
            class="faq-shortcut"
          >
            {#if faq.translateShortCut}{faq.translateShortCut}{:else}{faq.shortcutName}{/if}
            <img class="sml-ico" alt={faq.shortcutName} src={getproperty(faq.shortcutName, "icon")} />
          </span>
        {/if}
      {/each}
    </div>
    <div class="faqs-content" hidden={hideFaqs}>
      {#each faqs.faqs as faq, i}
        {#if faq.shortcutName}
          <NewSingleFaq
            activeFaq={(faqId === faq.id && hidden === false) || showAll}
            {faq}
            allowToggle={false}
            noQuestion={true}
            {fullScreenMobile}
          />
        {:else}
          <NewSingleFaq activeFaq={true} {faq} allowToggle={true} bottomBorder={bottomBorder(i)} {fullScreenMobile} />
        {/if}
      {/each}
    </div>
    <div class="chatwith-faqs-buttons">
      {#if activePerson.online === true}
        <Button on:click={() => dispatch("problem-not-solved")} buttonText={"WhatsApp"} showIcon={true} />
      {:else if data.widgetType !== "bigperson"}
        <Button on:click={() => dispatch("leave-message")} buttonText={data.leaveMessage ?? "Leave a message"} showIcon={false} />
      {/if}
    </div>
  </div>
</div>
