<script>
  import { trimUrlPeriod } from "../../utils/utilities";

  export let text;
  export let showDot;

  async function wait() {
    if (!showDot) return true;
    await new Promise((resolve) => setTimeout(resolve, 2000));
    return true;
  }

  function replaceURLsWithLinks(text) {
    // Regular expression to match URLs in the text
    const urlRegex = /https?:\/\/[^\s]+/g;

    // Replace URLs with <a> tags
    return text.replace(urlRegex, (url) => {
      url = trimUrlPeriod(url);
      return `<br/><br/><b><a href="${url}" target="_blank">${url}</a></b><br/><br/>`;
    });
  }
</script>

<div class="chatwith-bubble-bot">
  {#await wait()}
    <div class="chatwith-bubble-bot-dots">
      <span class="chatwith-bubble-bot-dot" />
      <span class="chatwith-bubble-bot-dot" />
      <span class="chatwith-bubble-bot-dot" />
    </div>
  {:then v}
    {@html replaceURLsWithLinks(text)}
  {/await}
</div>
