<script>
  import { createEventDispatcher } from "svelte";

  const dispatch = createEventDispatcher();

  export let data;
  export let item;
  export let value;
  export let index;
  export let validateKey;

  let isError = false;
  let err = "";

  const fileValidate = (file) => {
    if (file.size > 5368709120 || !["pdf", "docx", "xlsx", "jpg", "jpeg", "png", "zip"].includes(file.name.split(".").pop().toLowerCase())) {
      return false;
    }

    return true;
  };

  export const validate = () => {
    if (item.val === undefined) item.val = "";
    if (item.type === "selectBooking") {
      let hasError = false;

      if (item.required) {
        // Validate the select field
        if (!item.selectVal || item.selectVal === "") {
          err = data.requiredValidationText ? data.requiredValidationText : "Booking category is required";
          if (value.findIndex((c) => c.index === index) === -1) value.push({ index: index });
          isError = true;
          hasError = true;
        }

        // Validate the date field
        if (!item.dateVal || item.dateVal === "") {
          err = data.requiredValidationText ? data.requiredValidationText : "Booking date is required";
          if (value.findIndex((c) => c.index === index) === -1) value.push({ index: index });
          isError = true;
          hasError = true;
        }
      }
      if (!hasError) {
        if (value.findIndex((c) => c.index === index) > -1) value.splice(value.findIndex((c) => c.index === index));
        isError = false;
      }
    } else if (item.type === "selectBookingFullDay") {
      let hasError = false;

      if (item.required) {
        // Validate the select field
        if (!item.selectVal || item.selectVal === "") {
          err = data.requiredValidationText ? data.requiredValidationText : "Booking category is required";
          if (value.findIndex((c) => c.index === index) === -1) value.push({ index: index });
          isError = true;
          hasError = true;
        }

        if (!item.startDateVal || item.startDateVal === "" || !item.endDateVal || item.endDateVal === "") {
          err = data.requiredValidationText ? data.requiredValidationText : "Booking date range is required";
          if (value.findIndex((c) => c.index === index) === -1) value.push({ index: index });
          isError = true;
          hasError = true;
        }
      }
      // Validar el rango de días entre startDateVal y endDateVal
      if (item.minDuration || item.maxDuration) {
        const startDate = new Date(item.startDateVal);
        const endDate = new Date(item.endDateVal);
        const diffTime = endDate - startDate;
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); // Diferencia en días

        if (item.minDuration && diffDays < item.minDuration) {
          err = data.minDurationValidationText ? data.minDurationValidationText : `The minimum duration is ${item.minDuration} days`;
          if (value.findIndex((c) => c.index === index) === -1) value.push({ index: index });
          isError = true;
          hasError = true;
        }
        if (item.maxDuration && diffDays > item.maxDuration) {
          err = data.maxDurationValidationText ? data.maxDurationValidationText : `The maximum duration is ${item.maxDuration} days`;
          if (value.findIndex((c) => c.index === index) === -1) value.push({ index: index });
          isError = true;
          hasError = true;
        }
      }

      if (!hasError) {
        if (value.findIndex((c) => c.index === index) > -1) value.splice(value.findIndex((c) => c.index === index));
        isError = false;
      }
    } else if (item.type === "selectBookingAdvanced") {
      let hasError = false;
      err = "";

      if (item.required) {
        // Validate the select field
        if (!item.selectVal || item.selectVal === "") {
          err = data.requiredValidationText ? data.requiredValidationText : "Booking category is required";
          if (value.findIndex((c) => c.index === index) === -1) value.push({ index: index });
          isError = true;
          hasError = true;
        }
      }

      // Validate the min and max amount for the selected item
      let selectedOption = item.dataObject.find((option) => option.value === item.selectVal);
      if (selectedOption) {
        if (selectedOption.canMultiplyMin && item.amount < selectedOption.canMultiplyMin) {
          err += data.minAmountValidationText ? data.minAmountValidationText : `The minimum amount is ${selectedOption.canMultiplyMin}`;
          if (value.findIndex((c) => c.index === index) === -1) value.push({ index: index });
          isError = true;
          hasError = true;
        }
        if (selectedOption.canMultiplyMax && item.amount > selectedOption.canMultiplyMax) {
          err += data.maxAmountValidationText ? data.maxAmountValidationText : `The maximum amount is ${selectedOption.canMultiplyMax}`;
          if (value.findIndex((c) => c.index === index) === -1) value.push({ index: index });
          isError = true;
          hasError = true;
        }
      }

      if (!hasError) {
        if (value.findIndex((c) => c.index === index) > -1) value.splice(value.findIndex((c) => c.index === index));
        isError = false;
      }
    } else if (item.type === "selectAndPrice") {
      let hasError = false;
      err = "";

      if (item.required) {
        // Validate the select field
        if (!item.val || item.val === "") {
          err = data.requiredValidationText ? data.requiredValidationText : "This field is required. ";
          if (value.findIndex((c) => c.index === index) === -1) value.push({ index: index });
          isError = true;
          hasError = true;
        }
      }

      // Validate the min and max amount for the selected item
      let selectedOption = item.dataObject.find((option) => option.value === item.val);
      if (selectedOption) {
        if (selectedOption.canMultiplyMin && item.amount < selectedOption.canMultiplyMin) {
          err += data.minAmountValidationText ? data.minAmountValidationText : `The minimum amount is ${selectedOption.canMultiplyMin}`;
          if (value.findIndex((c) => c.index === index) === -1) value.push({ index: index });
          isError = true;
          hasError = true;
        }
        if (selectedOption.canMultiplyMax && item.amount > selectedOption.canMultiplyMax) {
          err += data.maxAmountValidationText ? data.maxAmountValidationText : `The maximum amount is ${selectedOption.canMultiplyMax}`;
          if (value.findIndex((c) => c.index === index) === -1) value.push({ index: index });
          isError = true;
          hasError = true;
        }
      }

      if (!hasError) {
        if (value.findIndex((c) => c.index === index) > -1) value.splice(value.findIndex((c) => c.index === index));
        isError = false;
      }
    } else if (item.type === "number") {
      let hasError = false;
      if (item.required) {
        if (!item.val || item.val === "") {
          err = data.requiredValidationText ? data.requiredValidationText : "This field is required. ";
          if (value.findIndex((c) => c.index === index) === -1) value.push({ index: index });
          isError = true;
          hasError = true;
        }
      }

      // Validate the value is a number
      if (item.val && item.val !== "" && !/^\d+$/.test(item.val)) {
        err = data.numberValidationText ? data.numberValidationText : "This is not a number.";
        if (value.findIndex((c) => c.index === index) === -1) value.push({ index: index });
        isError = true;
        hasError = true;
      }

      // Validate min and max values
      if (item.min && item.val < item.min) {
        err = data.minNumberValidationText ? data.minNumberValidationText : `The minimum value is ${item.min}`;
        if (value.findIndex((c) => c.index === index) === -1) value.push({ index: index });
        isError = true;
        hasError = true;
      }

      if (item.max && item.val > item.max) {
        err = data.maxNumberValidationText ? data.maxNumberValidationText : `The maximum value is ${item.max}`;
        if (value.findIndex((c) => c.index === index) === -1) value.push({ index: index });
        isError = true;
        hasError = true;
      }

      if (!hasError) {
        if (value.findIndex((c) => c.index === index) > -1) value.splice(value.findIndex((c) => c.index === index));
        isError = false;
      }
    } else {
      if (
        item.required &&
        item.type !== "hidden" &&
        item.type !== "freetext" &&
        (item.val === "" || item.val === false || (item.type == "tel" && item.telNum === ""))
      ) {
        err = data.requiredValidationText ? data.requiredValidationText : "This field is required";
        if (value.findIndex((c) => c.index === index) === -1) value.push({ index: index });
        isError = true;
      } else if (item.type === "number" && item.val !== "" && !/^\d+$/.test(item.val)) {
        err = data.numberValidationText ? data.numberValidationText : "This is not a number.";
        if (value.findIndex((c) => c.index === index) === -1) value.push({ index: index });
        isError = true;
      } else if (
        item.type === "url" &&
        item.val !== "" &&
        !/(http(s)?:\/\/)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g.test(item.val)
      ) {
        err = data.urlValidationText ? data.urlValidationText : "Please use a https:// or http:// URL.";
        if (value.findIndex((c) => c.index === index) === -1) value.push({ index: index });
        isError = true;
      } else if (
        item.type === "tel" &&
        item.telNum !== "" &&
        (!/^\d{6,15}$/.test(item.telNum) || (item.telCode === "34" && !/^[6-8]\d{8}$/.test(item.telNum) && !/^[9]\d{8,9}$/.test(item.telNum)))
      ) {
        err = data.telValidationText ? data.telValidationText : "This is not a phone number.";
        if (value.findIndex((c) => c.index === index) === -1) value.push({ index: index });
        isError = true;
      } else if (item.type === "email" && item.val !== "" && !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,11})+$/.test(item.val)) {
        err = data.emailValidationText ? data.emailValidationText : "Sorry but this email is not valid.";
        if (value.findIndex((c) => c.index === index) === -1) value.push({ index: index });
        isError = true;
      } else if (item.type === "file" && !fileValidate(item.val)) {
        err = data.fileValidationText ? data.fileValidationText : "Sorry but this file is not valid.";
        if (value.findIndex((c) => c.index === index) === -1) value.push({ index: index });
        isError = true;
      } else if (item.type === "zipcode" && item.val !== "" && item.validation) {
        const regExpPattern = item.validation.replace(/^\/|\/$/g, "");
        const regExp = new RegExp(regExpPattern);

        if (!regExp.test(item.val)) {
          err = data.zipcodeValidationText ? data.zipcodeValidationText : "This zipcode is not valid.";
          if (value.findIndex((c) => c.index === index) === -1) value.push({ index: index });
          isError = true;
        } else {
          if (value.findIndex((c) => c.index === index) > -1) value.splice(value.findIndex((c) => c.index === index));
          isError = false;
        }
      } else {
        if (value.findIndex((c) => c.index === index) > -1) value.splice(value.findIndex((c) => c.index === index));
        isError = false;
      }
    }

    dispatch("error", isError);
  };
</script>

{#if isError}
  <div class="chatwith-error-text">
    {err}
  </div>
{/if}

<style>
  .chatwith-error-text {
    padding: 3px;
    color: #ff8076;
    font-weight: bold;
    font-size: 10pt;
    margin-top: 3px;
    border-radius: 5px;
    text-align: left;
  }
</style>
