<script>
  import { getWrittenName, splitNameAndPrice, toArray } from "../../utils/utilities";

  export let item;
  export let selectOption;
  export let unselectOption;
  export let currency;
</script>

<div class="chatwith-button-options">
  {#each toArray(item.data) as option}
    <button
      type="button"
      class="chatwith-option-button {item.type === 'selectBookingAdvanced'
        ? item.selectVal == option
          ? 'selected'
          : ''
        : item.val == option
        ? 'selected'
        : ''}"
      on:click={(e) => {
        if (item.type === "selectBookingAdvanced") {
          if (item.selectVal === option) {
            item.selectVal = "";
            unselectOption(option, item, e.target);
          } else {
            item.selectVal = option;
            selectOption(option, item, e.target);
          }
        } else {
          if (item.val === option) {
            item.val = "";
            unselectOption(option, item, e.target);
          } else {
            item.val = option;
            selectOption(option, item, e.target);
          }
        }
      }}
    >
      <div class="chatwith-option-button-text">
        <span class="chatwith-option-button-name"
          >{splitNameAndPrice(getWrittenName(option, item?.dataObject, item?.showPrices, currency, item?.showDurations)).name}</span
        >
        {#if item?.showPrices}
          <small class="chatwith-option-button-price"
            >({splitNameAndPrice(getWrittenName(option, item?.dataObject, item?.showPrices, currency, item?.showDurations)).price})</small
          >
        {/if}
        {#if item?.showDurations}
          <small class="chatwith-option-button-duration"
            >({splitNameAndPrice(getWrittenName(option, item?.dataObject, item?.showPrices, currency, item?.showDurations)).duration})</small
          >
        {/if}
      </div>
    </button>
  {/each}
</div>
