<script>
  import { createEventDispatcher, onMount } from "svelte";
  import Spinner from "../base/Spinner.svelte";
  import { currentLanguage } from "../../store";
  import translations from "../../translations";
  import { getAdvancedBookingDays, getAdvancedBookingTimes } from "../../utils/getdata";
  import BackButton from "../booking/BackButton.svelte";

  const dispatch = createEventDispatcher();

  let currentWeekStart = new Date();
  let days = [];
  let selectedDay = null;
  let availableTimeSlots = [];
  let selectedTimeSlot = null;
  let isLoading = false;
  let currentLang = navigator.language.split("-")[0] || "en";
  let advancedDays = {};

  export let requiredError = false;
  export let selectedBookingAdvancedCategoryIds;

  $: displayedMonths = days.length > 0 ? Array.from(new Set(days.map((day) => getMonthName(day)))).join(" - ") : "";

  onMount(() => {
    generateDays();
    const foundToday = days.find((day) => isToday(day));
    if (foundToday) {
      selectDay(foundToday);
    }
  });

  // Generate days of the week
  function generateDays() {
    days = [];
    const start = new Date(currentWeekStart);
    for (let i = 0; i < 7; i++) {
      let day = new Date(start);
      day.setDate(start.getDate() + i);
      days.push(day);
    }
    fetchAdvancedDays();
  }

  async function fetchAdvancedDays() {
    const fromDate = days[0].toISOString().split("T")[0];
    advancedDays = await getAdvancedBookingDays(selectedBookingAdvancedCategoryIds, fromDate);
  }

  function prevWeek() {
    currentWeekStart = new Date(currentWeekStart);
    currentWeekStart.setDate(currentWeekStart.getDate() - 7);
    generateDays();
  }

  function nextWeek() {
    currentWeekStart = new Date(currentWeekStart);
    currentWeekStart.setDate(currentWeekStart.getDate() + 7);
    generateDays();
  }

  async function selectDay(day) {
    selectedDay = day;
    // Reset selected time slot
    selectedTimeSlot = null;
    // Get available time slots
    const date = selectedDay.toISOString().split("T")[0];
    availableTimeSlots = await getAdvancedBookingTimes(selectedBookingAdvancedCategoryIds, date);
    availableTimeSlots = availableTimeSlots.map((slot) => slot.time);
    let now = new Date();
    if (selectedDay.toDateString() === now.toDateString()) {
      availableTimeSlots = availableTimeSlots.filter((slot) => {
        slot = slot.slice(0, 16);
        const time = new Date(slot);
        return time > now;
      });
    }
  }

  // When a time slot is selected, emit the bookingSelected event
  function selectTimeSlot(slot) {
    selectedTimeSlot = slot;
    dispatch("bookingSelected", {
      date: selectedDay,
      time: selectedTimeSlot,
    });
  }

  // Check if a day is today
  function isToday(day) {
    const today = new Date();
    return day.getDate() === today.getDate() && day.getMonth() === today.getMonth() && day.getFullYear() === today.getFullYear();
  }

  // Get the month name of a date
  function getMonthName(date) {
    return date.toLocaleDateString(navigator.language, { month: "long" });
  }
</script>

<div class="cw-bookings-advanced-calendar-container">
  <div class="advanced-booking-back-btn">
    <button on:click={() => dispatch("back")}><BackButton /></button>
  </div>
  <div class="month-header">
    <button class="arrow-btn" on:click={prevWeek}>&lt;</button>
    <span>{displayedMonths}</span>
    <button class="arrow-btn" on:click={nextWeek}>&gt;</button>
  </div>

  <div class="calendar-header">
    <div class="days-container">
      {#each days as day}
        <div
          class="day-circle {isToday(day) ? 'today' : ''} {selectedDay && day.toDateString() === selectedDay.toDateString()
            ? 'selected'
            : ''} {advancedDays[day.toISOString().split('T')[0]] === false ? 'not-available' : ''}"
          on:click={() => selectDay(day)}
        >
          <div class="day-content">
            <small>{day.toLocaleDateString(navigator.language, { weekday: "short" }).toUpperCase()}</small>
            <span>{day.getDate()}</span>
          </div>
        </div>
      {/each}
    </div>
  </div>

  {#if selectedDay}
    <div class="selected-day">
      {selectedDay.toLocaleDateString(navigator.language, { weekday: "long" })}, {selectedDay.getDate()}
      {selectedDay.toLocaleDateString(navigator.language, { month: "long" })}
    </div>
  {/if}

  {#if requiredError}
    <small class="chatwith-error-text" style="color: red; margin-bottom: 10px;">{translations.selectTimeRequired[currentLang]}</small>
  {/if}

  {#if availableTimeSlots.length > 0}
    <div class="timeslots-container">
      {#each availableTimeSlots as slot}
        <div class="timeslot {selectedTimeSlot === slot ? 'selected' : ''}" on:click={() => selectTimeSlot(slot)}>
          {slot.slice(11, 16)}
        </div>
      {/each}
    </div>
  {/if}
  {#if availableTimeSlots.length === 0 && !isLoading}
    <small><i>{translations.noAvailableTimeSlots[currentLang]}</i></small>
  {/if}
  {#if isLoading}
    <div>
      <Spinner />
    </div>
  {/if}
</div>
