<script>
  import { createEventDispatcher, onMount } from "svelte";
  import flatpickr from "flatpickr";

  $: value = "";
  export let placeholder = "Select time";

  const dispatch = createEventDispatcher();

  const action = () => {
    dispatch("message", value);
    value = "";
  };

  let input;

  const config = {
    enableTime: true,
    noCalendar: true,
    dateFormat: "H:i",
    time_24hr: true,
    disableMobile: true,
    onChange: (selectedDates, dateStr, instance) => {
      value = dateStr;
    },
  };

  onMount(() => {
    flatpickr(input, config);
  });
</script>

<div class="chatwith-chat-time-picker">
  <input type="text" {placeholder} bind:this={input} />
  <div class="chatwith-chat-time-picker-button">
    <button on:click={() => action()}>
      <svg width="24px" height="24px" viewBox="0 0 32 32" id="icon" xmlns="http://www.w3.org/2000/svg"
        ><defs
          ><style>
            .cls-1 {
              fill: none;
            }
          </style></defs
        >
        <path
          fill="#fff"
          d="M27.45,15.11l-22-11a1,1,0,0,0-1.08.12,1,1,0,0,0-.33,1L7,16,4,26.74A1,1,0,0,0,5,28a1,1,0,0,0,.45-.11l22-11a1,1,0,0,0,0-1.78Zm-20.9,10L8.76,17H18V15H8.76L6.55,6.89,24.76,16Z"
        />
      </svg>
    </button>
  </div>
</div>
