import { isClosedConversationStore } from "../store";
import { dataObj } from "./getdata";
import { dataAttributes } from './getdata'
import { sendBookingDataToGA } from './sendBookingToGA'

function tagging(person, extraData) {
    let event = 'chatwith';

    isClosedConversationStore.subscribe(value => {
        if (value) {
            event = 'CW_LeadClose';
        };
    });

    let extras = {}

    extras = {
        'widget': person.businessGaTag ? person.businessGaTag : 'whatapp',
        'agent': person.gaTag ? person.gaTag : person.id
    }

    if (typeof window.fbq === 'function') {
        //console.log("fb")
        window.fbq('trackCustom', 'chatWithEvent', extras);
    }

    if (typeof window.gtag === "function") {
        console.log("old gtag")
    }

    if (typeof window.dataLayer === 'object') {
        console.log("GA dataLayer")
        window.dataLayer.push({
            'event': event,
            'cw_event_category': person.businessGaTag ? person.businessGaTag : 'whatsapp',
            'cw_event_action': 'chatwith',
            'cw_event_label': person.gaTag ? person.gaTag : person.id,
            'cw_event_name': extraData?.firstname ? extraData.firstname : '',
            'cw_event_email': extraData?.email ? extraData.email : '',
            'cw_event_phone': extraData?.tel ? extraData.tel : ''
        });
    }

    sendBookingDataToGA()
}

export { tagging };
